import {Injectable} from "@angular/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {ApplicationConfigService} from "core-ui";
import {Observable} from "rxjs";
import {P1DashboardData, P2DashboardData} from "../model/dashboard-model";
import {serviceConstants} from "../core/service-constants";
import {TagSearchResult} from "../dashboard/dashboard-components/data-view/typeahead/design.model";

@Injectable({ providedIn: 'root' })
export class DashboardService {
  public static WELCOME = 'WELCOME';
  public static WELCOME_SUMMARY = 'WELCOME_SUMMARY';
  public static BENCHMARK = 'BENCHMARK';
  public static BENCHMARK_PRIMARY_ORG_TYPE = 'BENCHMARK_PRIMARY_ORG_TYPE';
  public static BENCHMARK_SECONDARY_ORG_TYPE = 'BENCHMARK_SECONDARY_ORG_TYPE';

  protected surveyRespUrl = this.applicationConfigService.getEndpointFor('api/alt/dashboard', serviceConstants.DYNAMIC_CLIENT);

  constructor(protected http: HttpClient, protected applicationConfigService: ApplicationConfigService) {}


  fetchSurveyResp(dashboardType: string, selectedTagId: number = 0): Observable<HttpResponse<P2DashboardData>> {

    const selectedTagIdPostOptions = selectedTagId === 0 ? '' : '/' + selectedTagId;

    return this.http.get<P2DashboardData>(
      `${this.surveyRespUrl}/${dashboardType}${selectedTagIdPostOptions}`,
      { observe: 'response' }
    );
  }

  fetchDashboardData(dashboardType: string, tagId: number = 0): Observable<HttpResponse<P1DashboardData>> {

    return this.http.get<P1DashboardData>(
      `${this.surveyRespUrl}/generic/${dashboardType}?tagIds=` + tagId,
      { observe: 'response' }
    );
  }

  getAllTagTypes(): Observable<HttpResponse<any[]>> {
    return this.http
      .get<any[]>(
        `${this.applicationConfigService.getEndpointFor('api/alt/tag-types', serviceConstants.DYNAMIC_CLIENT)}`,
        {
          observe: 'response',
          // Specify caching options
          headers: {
            'Cache-Control': 'max-age=3600', // Cache for 1 hour (adjust as needed)
            'If-Modified-Since': 'Mon, 01 Jan 1970 00:00:00 GMT' // Ensure fresh request
          }
        }
      );
  }

  prepare(resp: HttpResponse<any[]>): string[] {
    const tagTypes = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < resp.body.length; i++) {
      tagTypes[resp.body[i].id] = resp.body[i].displayName == null ? resp.body[i].name : resp.body[i].displayName
    }

    console.log(tagTypes)

    return tagTypes;
  }

  getPlumes(): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(this.applicationConfigService.getEndpointFor('api/alt/survey-responses/surveyor-pluim',
      serviceConstants.DYNAMIC_CLIENT), {observe: 'response', params: {page: 0, size: 5000}})
  }

  findAllTags(tagTypeId: number): Observable<HttpResponse<TagSearchResult[]>> {
    return this.http.get<TagSearchResult[]>(
      this.applicationConfigService.getEndpointFor(`api/alt/tags-by-type/${tagTypeId}`, serviceConstants.DYNAMIC_CLIENT),
      {observe: 'response'}
    );
  }
}
