import {Injectable} from "@angular/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {ApplicationConfigService} from "core-ui";
import {Observable} from "rxjs";
import {SurveyMinimal} from "../model/surveyor.model";
import {commonConstants} from "../../../environments/common.constants";

@Injectable({ providedIn: 'root' })
export class DynamicClientService {


  protected baseUrl = "/services/dynamicclient";

  protected resourceUrl = this.applicationConfigService.getEndpointFor('api/alt/surveys', 'dynamicclient');
  protected resourceUrlById = this.applicationConfigService.getEndpointFor('api/surveys', 'dynamicclient');

  constructor(protected http: HttpClient, protected applicationConfigService: ApplicationConfigService) {}

  getAllActiveSurveys(clientDbName?: string): Observable<HttpResponse<SurveyMinimal[]>> {
    return this.http.get<SurveyMinimal[]>(`${this.resourceUrl}`, {
      headers: clientDbName ? { 'X-DATABASE-NAME': clientDbName } : {},
      params: {activeOnly: false},
      observe: 'response',
    });
  }


  getSurveyById(id: number): Observable<HttpResponse<SurveyMinimal>> {
    return this.http.get<SurveyMinimal>(`${this.resourceUrlById}/${id}`, {
      observe: 'response',
    });
  }

  getSavedSurvey(orElse: number): number {
    let id = localStorage.getItem(commonConstants.X_SESSION_SURVEY_ID)
    if (id === null) {
      return orElse;
    }
    return Number(id)
  }

  saveSurvey(surveyId: number) {
    localStorage.setItem(commonConstants.X_SESSION_SURVEY_ID, '' + surveyId)
  }
}
