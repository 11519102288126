import {ModuleWithProviders, NgModule} from '@angular/core';
import { CoreUiComponent } from './core-ui.component';
import {NgxWebstorageModule} from "ngx-webstorage";
import {httpInterceptorProviders} from "./core/interceptor";
import {
  IBaseServiceInfoProvidersService
} from "./core/providers/base-service-info-providers.service";
import {HasAnyAuthorityDirective} from "./core/directives/auth/has-any-authority.directive";



@NgModule({
  declarations: [
    CoreUiComponent,
    HasAnyAuthorityDirective
  ],
  imports: [
    NgxWebstorageModule.forRoot({ prefix: 'jhi', separator: '-', caseSensitive: true })
  ],
  exports: [
    CoreUiComponent,
    HasAnyAuthorityDirective
  ],
  providers: [
    httpInterceptorProviders
  ]
})
export class CoreUiModule {
  static forRoot(baseServiceProvider: IBaseServiceInfoProvidersService): ModuleWithProviders<CoreUiModule> {
    return {
      ngModule: CoreUiModule,
      providers: [
        {
          provide: 'baseServiceProvider',
          useValue: baseServiceProvider
        }
      ]
    }
  }
}
