import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { SessionStorageService } from 'ngx-webstorage';
import { Observable, ReplaySubject, of } from 'rxjs';
import { shareReplay, tap, catchError } from 'rxjs/operators';
import {Account} from "./account.model";

import { ApplicationConfigService } from '../config/application-config.service';
import {StateStorageService} from "./state-storage.service";
import {StorageService} from "../storage/storage-service";
import {AuthServerProvider} from "./auth-jwt.service";

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userIdentity: Account | null = null;
  private authenticationState = new ReplaySubject<Account | null>(1);
  private accountCache$?: Observable<Account | null>;
  private USER_IDENTITY = "USER_IDENTITY";
  private SURVEY_ID= 'X-SESSION-SURVEY-ID';

  constructor(
    private sessionStorageService: SessionStorageService,
    private http: HttpClient,
    private stateStorageService: StateStorageService,
    private router: Router,
    private applicationConfigService: ApplicationConfigService,
    private storageService: StorageService,
    private jwtService: AuthServerProvider
  ) {
  }

  save(account: Account): Observable<{}> {
    return this.http.post(this.applicationConfigService.getEndpointFor('api/account'), account);
  }

  authenticate(identity: Account | any | null): void {
    if (identity === null) {
      this.clearAll()
    }
    this.storageService.remove(this.USER_IDENTITY);

    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);
    if (this.userIdentity === undefined || this.userIdentity === null) {
      this.storageService.put(this.USER_IDENTITY, JSON.stringify(this.userIdentity));
    } else {
      this.storageService.put(this.USER_IDENTITY, JSON.stringify(this.userIdentity));
    }
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    const value = this.storageService.get(this.USER_IDENTITY);
    if (!value) {
      return false;
    }
    const userIdentityNow:Account = JSON.parse(value)
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return userIdentityNow.authorities.some((authority: string) => authorities.includes(authority));
  }

  identity(force?: boolean, clearExisting: boolean = false): Observable<Account | null> {
    if (clearExisting) {
      this.cleanSurveyId()
    }
    if (!this.accountCache$ || force || !this.isAuthenticated()) {
      this.accountCache$ = this.fetch().pipe(
        catchError(() => of(null)),
        tap((account: Account | null) => {
          this.authenticate(account);

          if (account) {
            this.navigateToStoredUrl();
          }
        }),
        shareReplay()
      );
    }
    return this.accountCache$;
  }

  identityByJwt(jwt: string): Observable<Account | null> {
    this.accountCache$ = this.fetchAccount(jwt).pipe(
      catchError(() => of(null)),
      tap((account: Account | null) => {
        this.authenticate(account);
        this.jwtService.authenticateSuccess({id_token: jwt}, true);
        if (account) {
          this.navigateToStoredUrl();
        }
      }),
      shareReplay()
    );
    return this.accountCache$;
  }

  isAuthenticated(): boolean {
    return this.storageService.get(this.USER_IDENTITY) !== null;
  }

  getAuthenticationState(): Observable<Account | null> {
    return this.authenticationState.asObservable();
  }

  private fetch(): Observable<Account> {
    const srvyId  = localStorage.getItem('X-SESSION-SURVEY-ID');
    let params = {selectedSurveyId: ''};

    if (srvyId !== null) {
      params.selectedSurveyId = srvyId;
    }

    return this.http.get<Account>(
      this.applicationConfigService.getEndpointFor('api/account-alt'),
      {params: params}
    );
  }

  private fetchAccount(jwt: string): Observable<Account> {
    const srvyId  = localStorage.getItem(this.SURVEY_ID);
    let params = {selectedSurveyId: ''};

    if (srvyId !== null) {
      params.selectedSurveyId = srvyId;
    }

    return this.http.get<Account>(
      this.applicationConfigService.getEndpointFor('api/account-alt'),
      {
        params: params,
        headers: {Authorization: 'Bearer ' + jwt}
      }
    );
  }

  private navigateToStoredUrl(): void {
    // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      this.stateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
    }
  }

  public clientName(): string | null {
    const value = this.storageService.get(this.USER_IDENTITY);
    if (!value) {
      return null;
    }
    const userIdentityNow:Account = JSON.parse(value)
    return userIdentityNow.clientName;
  }

  public clientPathSuffix(): string | null {
    const value = this.storageService.get(this.USER_IDENTITY);
    if (!value) {
      return null;
    }
    const userIdentityNow:Account = JSON.parse(value)
    return userIdentityNow.clientPathSuffix;
  }

  public cacheVersion(): string | null {
    const value = this.storageService.get(this.USER_IDENTITY);
    if (!value) {
      return null;
    }
    const userIdentityNow:Account = JSON.parse(value)
    return userIdentityNow.cacheVersion;
  }

  getPathExtension(token: string | undefined, account: Account, surveyType: string | undefined): string | null {
    if (surveyType === undefined || surveyType === null || surveyType === 'P2_SURVEY') {
      return account.p2Extension;
    } else {
      return account.p1Extension;
    }
  }

  getSurveyId(): number | null {
    const value = this.storageService.get(this.USER_IDENTITY);
    if (!value) {
      return null;
    }
    const userIdentityNow:Account = JSON.parse(value)
    return userIdentityNow.surveyId;

  }

  getSurveyType(): string | null {
    const value = this.storageService.get(this.USER_IDENTITY);
    if (!value) {
      return null;
    }
    const userIdentityNow:Account = JSON.parse(value)
    return userIdentityNow.surveyType;

  }

  private clearAll() {
    this.storageService.remove(this.USER_IDENTITY);
    localStorage.removeItem(this.SURVEY_ID)
  }

  private cleanSurveyId() {
    localStorage.removeItem(this.SURVEY_ID)
  }
}
