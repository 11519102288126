import {InjectionToken} from "@angular/core";

export interface IBaseServiceInfoProvidersService {
  apiBaseUrl(): string
}

export class AbstractBaseServiceInfoProvidersService implements IBaseServiceInfoProvidersService {
  constructor(
    private baseUrl: string
  ) {
  }

  apiBaseUrl(): string {
    return this.baseUrl;
  }
}


export const BaseServiceProvider = new InjectionToken (
  'baseServiceProvider'
)
