import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";

export class PrintData {
  hasPrint = false;
  printBlockName = 'printableArea';
}

@Injectable({providedIn: 'root'})
export class PrintObserver {
    printDataObserver = new ReplaySubject<PrintData | null>(1);

    publishEvent(printData?: PrintData): void {
      if (!printData) {
        printData = new PrintData();
        printData.hasPrint = true;
      }
      this.printDataObserver.next(printData);
    }

    publishEventNoPrint(): void {
      this.printDataObserver.next(new PrintData());
    }

    getEventState(): Observable<PrintData | null> {
      return this.printDataObserver.asObservable();
    }
}
