import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthorityProviderService {
  user(): string {
    return 'ROLE_USER';
  }

  admin(): string {
    return 'ROLE_ADMIN';
  }

  clientAdmin(): string {
    return 'ROLE_CLIENT';
  }

  developer(): string {
    return 'ROLE_DEVELOPER';
  }
}
