import {DOCUMENT} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Renderer2
} from '@angular/core';
import {GuardsCheckEnd, Router} from '@angular/router';
import {ConfigService} from 'src/app/config/config.service';
import {RightSidebarService} from 'src/app/core/service/rightsidebar.service';
import {WINDOW} from 'src/app/core/service/window.service';
import {LanguageService} from 'src/app/core/service/language.service';
import {UnsubscribeOnDestroyAdapter} from 'src/app/shared/UnsubscribeOnDestroyAdapter';
import {Account, AccountService, LoginService} from "core-ui";


import {PrintData, PrintObserver} from "../../btsl/core/observer/print-observer";
import printHtmlBlock from 'print-html-block';
import {DynamicClientService} from "../../btsl/service/dynamic-client-service";
import {HttpResponse} from "@angular/common/http";
import {DashboardService} from "../../btsl/service/dashboard-service";
import {SurveyMinimal} from "../../btsl/model/surveyor.model";
import {commonConstants} from "../../../environments/common.constants";

// import wkhtmltopdf from 'wkhtmltopdf';

const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit
{
  public config: any = {};

  printData: PrintData | null;
  selectedSurvey: SurveyMinimal | null

  lastestRoute = '/btsl/dashboard/data-view/welcome'

  isNavbarCollapsed = true;
  isNavbarShow: boolean;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  isAuthenticated = false;
  surveyType = null;

  // language=html
  private headerOfPrinter = '<div class="container-fluid">' +
    '<h1>Kantell Onderzoek</h1>' +
    '<h5>Amsterdam, Netherlands</h5>' +
    '</div>' +
    '<br>' +
    '<br>' +
    '';
  tagTypes: string[] = [];
  surveys: SurveyMinimal[] = []

  constructor(
    @Inject(DOCUMENT) private documentObj: Document,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private accountService: AccountService,
    private loginService: LoginService,
    private router: Router,
    public languageService: LanguageService,
    private printObserver: PrintObserver,
    protected dashboardService: DashboardService,
    protected dynamicClientSvc: DynamicClientService
  ) {
    super();
    this.lastestRoute = router.url;
    this.router.events.subscribe(value => {
      if (value instanceof GuardsCheckEnd) {
        this.lastestRoute = value.url;
      }
    })
  }
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' }
  ];
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset =
      this.window.pageYOffset ||
      this.documentObj.documentElement.scrollTop ||
      this.documentObj.body.scrollTop ||
      0;
    if (offset > 50) {
      this.isNavbarShow = true;
    } else {
      this.isNavbarShow = false;
    }
  }
  ngOnInit() {
    this.listenAccount();
    this.listenOtherState();
    this.config = this.configService.configData;

    this.langStoreValue = localStorage.getItem('lang');
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }
  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.documentObj.body, this.config.layout.variant);
      this.renderer.addClass(this.documentObj.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.documentObj.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.documentObj.body,
        localStorage.getItem('menuOption')
      );
    } else {
      this.renderer.addClass(
        this.documentObj.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.documentObj.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        this.documentObj.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.documentObj.body, 'side-closed');
        this.renderer.addClass(this.documentObj.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.documentObj.body, 'side-closed');
        this.renderer.removeClass(this.documentObj.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.documentObj.body, 'side-closed');
        this.renderer.addClass(this.documentObj.body, 'submenu-closed');
      }
    }
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.documentObj.body, className);
    } else {
      this.renderer.addClass(this.documentObj.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.documentObj.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.documentObj.body, 'side-closed');
      this.renderer.removeClass(this.documentObj.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.documentObj.body, 'side-closed');
      this.renderer.addClass(this.documentObj.body, 'submenu-closed');
    }
  }
  public toggleRightSidebar(): void {
    this.subs.sink = this.rightSidebarService.sidebarState.subscribe(
      (isRunning) => {
        this.isOpenSidebar = isRunning;
      }
    );

    this.rightSidebarService.setRightSidebar(
      (this.isOpenSidebar = !this.isOpenSidebar)
    );
  }
  logout() {
    this.loginService.logout();
    this.router.navigate(['/authentication/signin']);
/*
    this.subs.sink = this.accountService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
*/
  }

  private listenAccount() {
    this.accountService.identity().subscribe(acc => {
      if (this.accountService.isAuthenticated()) {
        // this.router.navigate(['/btsl/dashboard']);
        this.isAuthenticated = true;
        this.surveyType = acc["surveyType"];
        console.log(this.surveyType)

        console.log('Tag type fetching..')
        this.dashboardService.getAllTagTypes()
          .subscribe((resp: HttpResponse<any[]>) => {
            this.tagTypes = this.dashboardService.prepare(resp)
          })

        this.dynamicClientSvc.getAllActiveSurveys()
          .subscribe((rsp: HttpResponse<SurveyMinimal[]>) => {
            this.surveys = rsp.body;
            this.allocateSurvey(this.surveys, acc)
          })

      } else {
        this.isAuthenticated = false;
        this.router.navigate(['/authentication/signin']);
      }
    });
  }

  navLink(url: string): string {
    return this.lastestRoute === url ? 'nav-link active-nav' : 'nav-link nav-link-not-active';
  }

  printComponent(cmpName): void {
    // this.printUsingWkhtmltoPdf(cmpName);
    // this.printUsingCtrlP(cmpName);

    const options = {
      importStyle: true
    };

    printHtmlBlock('#' + cmpName, options);
  }

  private listenOtherState() {
    this.printObserver.getEventState()
    .subscribe(val => {
      if (val) {
        this.printData = val;
      }
    })
  }

  /*private printUsingWkhtmltoPdf(cmpName) {
    const options = {
      importStyle: true
    };

    const printContents = document.getElementById(cmpName).innerHTML;

    // wkhtmltopdf(printContents, { pageSize: 'letter' }).pipe({});
    wkhtmltopdf(printContents, { pageSize: 'letter', spawnOptions: {shell: false}}).pipe({});
  }*/

  private printUsingCtrlP(cmpName) {
    const options = {
      importStyle: true
    };

    const printContents = document.getElementById(cmpName).innerHTML;

    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    location.reload();
    document.body.innerHTML = originalContents;
    // this.cd.detectChanges();
  }

  reloadSurvey(selectedSurvey: SurveyMinimal) {
    this.dynamicClientSvc.saveSurvey(this.selectedSurvey.id)
    this.router.navigate(['/'])
    location.reload()
  }

  shortenName(name: string): string {
    return name == null || name.length <= 35 ? name : (name.substring(0, 35) + ' ..');
  }

  private allocateSurvey(surveys: SurveyMinimal[], acc: Account) {

    const savedSurveyId = this.dynamicClientSvc.getSavedSurvey(acc.surveyId)

    for (const survey of surveys) {
      if (survey.id === savedSurveyId) {
        this.selectedSurvey = survey;
      }
    }
    if (this.selectedSurvey == null) {
      this.dynamicClientSvc.getSurveyById(savedSurveyId)
        .subscribe(resp => {
          this.selectedSurvey = {id: resp.body.id, name: resp.body.name};
          this.surveys.push(this.selectedSurvey)
        })
    }
  }
}
