import {Component, isDevMode, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: []
})
export class MainLayoutComponent implements OnInit {
  viewSideBar = environment.showNavBar

  constructor() {}

  ngOnInit(): void {}
}
