export const environment = {
  production: true,
  apiUrl: 'http://localhost:4200',
  showNavBar: false,
  apiBaseUrl: '',
  CACHE: {
    ENABLED: false,
    AFTER_REFRESH_CACHEABLE: true,
    LIFETIME: 30,
    LIFETIME_MID: 50,
    LIFETIME_HIGH: 80,
    LIFETIME_HIGHEST: 120
  }
};
