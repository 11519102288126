import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Login } from './login.model';
import {AccountService} from "./account.service";
import {AuthServerProvider} from "./auth-jwt.service";
import {Account} from "./account.model";

@Injectable({ providedIn: 'root' })
export class LoginService {
  constructor(private accountService: AccountService, private authServerProvider: AuthServerProvider) {}

  login(credentials: Login): Observable<Account | null> {
    return this.authServerProvider
      .login(credentials)
      .pipe(mergeMap(() => this.accountService.identity(true, true)));
  }

  logout(): void {
    this.authServerProvider.logout()
      .subscribe({ complete: () => this.accountService.authenticate(null) });
  }
}
