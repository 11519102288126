import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { ApplicationConfigService } from '../config/application-config.service';
import {AccountService} from "../auth/account.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private applicationConfigService: ApplicationConfigService,
    private accService: AccountService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let token: string | null =
      this.localStorageService.retrieve('authenticationToken') ?? this.sessionStorageService.retrieve('authenticationToken');

    console.log('<><><><><><TOKEN><><><><><>')
    console.log(token)
    token = token?.startsWith('"') ? token?.substring(1, token?.length-1) : token;
    console.log(token)
    console.log('<><><><><><TOKEN><><><><><>')

    const surveyId = this.accService.getSurveyId();
    const surveyType = this.accService.getSurveyType();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        }
      });
    }

    if (surveyId && surveyType) {
      request = request.clone({
        params: (request.params ? request.params : new HttpParams())
          .set('surveyId', surveyId)
          .set('surveyType', surveyType)
      });
    }
    return next.handle(request);
  }
}
