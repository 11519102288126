import {Inject, Injectable} from '@angular/core';
import {
  IBaseServiceInfoProvidersService
} from "../providers/base-service-info-providers.service";

@Injectable({
  providedIn: 'root',
})
export class ApplicationConfigService {
  constructor(
    @Inject('baseServiceProvider')
    private baseServiceProvider: IBaseServiceInfoProvidersService
  ) {
  }

  getEndpointFor(api: string, microservice?: string): string {
    if (microservice) {
      return `${this.baseServiceProvider.apiBaseUrl()}services/${microservice}/${api}`;
    }
    return `${this.baseServiceProvider.apiBaseUrl()}${api}`;
  }
}
