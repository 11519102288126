import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, indices: number[]): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }

    searchText = searchText.toLowerCase();

    return items.filter(item => {
      return item[indices[0]].toString().toLowerCase().includes(searchText)
        || item[indices[1]].toString().toLowerCase().includes(searchText);
    });
  }
}
