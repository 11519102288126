export class Account {
  constructor(
    public activated: boolean,
    public authorities: string[],
    public email: string,
    public firstName: string | null,
    public langKey: string,
    public lastName: string | null,
    public login: string,
    public imageUrl: string | null,
    public clientName: string | null,
    public clientPathSuffix: string | null,
    public cacheVersion: string | null,
    public p1Extension: string | null,
    public p2Extension: string | null,
    public surveyType: string | null,
    public surveyId: number | null
  ) {}
}
